<template>
  <div>
    <a-spin v-if="!showModal" :spinning="loading">
      <a-form ref="form" :model="formState" name="form" @finish="onSearch">
        <a-row>
          <a-form-item label="优惠券标题" name="title" class="ui-form__item">
            <a-input v-model:value="formState.title" placeholder="请输入优惠券标题"></a-input>
          </a-form-item>

          <a-form-item label="类型" name="type" class="ui-form__item">
            <a-select v-model:value="formState.type" style="width: 180px;" allow-clear placeholder="请选择类型">
              <a-select-option :value="1">代金券</a-select-option>
              <a-select-option :value="2">兑换券</a-select-option>
            </a-select>
          </a-form-item>

          <a-form-item label="状态" name="isDisabled" class="ui-form__item">
            <a-select v-model:value="formState.isDisabled" placeholder="请选择状态" allow-clear style="width: 180px;">
              <a-select-option :value="0">启用</a-select-option>
              <a-select-option :value="1">禁用</a-select-option>
            </a-select>
          </a-form-item>

          <a-form-item label="券用途" name="useLimit" class="ui-form__item">
            <a-select v-model:value="formState.useLimit" style="width: 180px;" allow-clear placeholder="请选择券用途">
              <a-select-option :value="1">影片</a-select-option>
              <a-select-option :value="2">演出</a-select-option>
              <a-select-option :value="3">卖品</a-select-option>
              <a-select-option :value="4">商城</a-select-option>
            </a-select>
          </a-form-item>

          <a-form-item label="有效期类型" name="validPeriodType" class="ui-form__item">
            <a-select v-model:value="formState.validPeriodType" placeholder="请选择有效期类型" allow-clear style="width: 180px;">
              <a-select-option :value="1">天数</a-select-option>
              <a-select-option :value="2">具体日期</a-select-option>
            </a-select>
          </a-form-item>

          <a-form-item class="ui-form__item" label="日期" name="periodDate">
            <a-range-picker v-model:value="formState.periodDate"/>
          </a-form-item>
        </a-row>

        <a-row>
          <a-col :span="18">
            <a-button v-permission="['coupon_snack_list_addCouponList']" type="primary" style="margin-right: 10px;" @click="onAdd">新增优惠券</a-button>
          </a-col>
          <a-col :span="6" style="text-align: right;">
            <a-button style="margin-right: 10px" type="primary" html-type="submit">搜索</a-button>
            <a-button @click="reset">重置</a-button>
          </a-col>
        </a-row>

      </a-form>

      <div style="margin-top: 20px;">
        <a-table :pagination="pagination" :columns="columns" :dataSource="list" rowKey="id" :scroll="{ x: 1500 }">
          <template #bodyCell="{ column, record }">
            <template v-if="column.key === 'type'">
              <div v-if="record.type === 1">代金券</div>
              <div v-if="record.type === 2">兑换券</div>
            </template>
            <template v-if="column.key === 'useLimit'">
              <div v-if="record.useLimit === 1">影片</div>
              <div v-if="record.useLimit === 2">演出</div>
              <div v-if="record.useLimit === 3">卖品</div>
              <div v-if="record.useLimit === 4">商城</div>
            </template>
            <template v-if="column.key === 'limitPrice'">
              <div v-if="record.limitPrice">{{ record.limitPrice }}</div>
              <div v-else>-</div>
            </template>
            <template v-if="column.key === 'isDisabled'">
              <a-tag v-if="!record.isDisabled" color="#87d068">启用</a-tag>
              <a-tag v-if="record.isDisabled" color="#f50">禁用</a-tag>
            </template>
            <template v-if="column.key === 'validPeriodType'">
              <div v-if="record.validPeriodType === 1">天数</div>
              <div v-if="record.validPeriodType === 2">具体日期</div>
            </template>
			<template v-if="column.key === 'period'">
				<div>
					<div v-if="record.validPeriodType === 1">{{ record.period }}</div>
					<div v-else>
						<div>{{ transDateTime(record.periodStartDate, 1) }}</div>
						至
						<div>{{ transDateTime(record.periodEndDate, 1) }}</div>
					</div>
				</div>
			</template>
            <template v-if="column.key === 'action'">
              <a-dropdown :trigger="['click', 'hover']">
                <a-button @click.prevent>操作 <Icon icon="DownOutlined"></Icon>
                </a-button>
                <template #overlay>
                  <a-menu>
                    <div v-permission="['coupon_snack_list_editCouponList']" @click="onEdit(record)">
                      <a-menu-item>
                        编辑
                      </a-menu-item>
                    </div>
                    <div v-permission="['coupon_snack_list_openCouponList']" v-if="record.isDisabled === 1" @click="onDisabled(record)">
                      <a-menu-item>启用</a-menu-item>
                    </div>
                    <div v-permission="['coupon_snack_list_closeCouponList']" v-else @click="onDisabled(record)">
                      <a-menu-item>禁用</a-menu-item>
                    </div>
                    <div v-if="record.isDisabled === 0" v-permission="['coupon_snack_list_sendCoupon']" @click="this.id = record.id">
                      <a-menu-item>
                        <couponList :id="id"></couponList>
                      </a-menu-item>
                    </div>
                    <div v-permission="['coupon_snack_list_useInfo']">
                      <a-menu-item @click="$router.push('/coupon/snack/use')">
                        使用情况
                      </a-menu-item>
                    </div>
                    <div v-if="record.isDisabled === 0" v-permission="['coupon_snack_list_createCode']">
                      <a-menu-item @click="onCouponCode(record)">
                        生成券码
                      </a-menu-item>
                    </div>
                  </a-menu>
                </template>
              </a-dropdown>
            </template>
          </template>
        </a-table>
      </div>

      <a-modal v-model:visible="showCouponCode" title="生成券码" width="800px" @ok="onCouponCodeOk">
        <a-spin :spinning="loading">
          <a-form ref="couponCodeForm" scrollToFirstError :model="couponCodeModelRef" name="couponCodeForm" :labelCol="{span: 8}" :wrapperCol="{span: 16}">
            <a-form-item label="生成类型" name="type">
              <a-radio-group v-model:value="couponCodeModelRef.type">
                <a-radio :value="1">批量生成券</a-radio>
                <a-radio :value="2">活动券</a-radio>
              </a-radio-group>
              <div style="color: #c2c2c2">批量生成券：生成带有兑换码的券</div>
              <div style="color: #c2c2c2"> 活动券：生成用于扫码领取券的活动码</div>
            </a-form-item>

            <a-form-item label="数量" name="num" :rules="[{ required: true, message: '必填项不允许为空' }]">
              <a-input-number style="width: 200px" :min="0" :precision="0" v-model:value="couponCodeModelRef.num" placeholder="请输入数量"></a-input-number>
            </a-form-item>

            <a-form-item label="领取截止日期" name="date" :rules="[{ required: true, message: '必填项不允许为空' }]">
<!--              <a-date-picker v-model:value="couponCodeModelRef.endDate"></a-date-picker>-->
              <a-range-picker v-model:value="couponCodeModelRef.date"></a-range-picker>
            </a-form-item>

            <a-form-item label="单用户领取上限" name="userLimitNum" extra="不输入默认不限制">
              <a-input-number style="width: 200px;" :min="0" :precision="0" v-model:value="couponCodeModelRef.userLimitNum" placeholder="请输入单用户领取上限"></a-input-number>
            </a-form-item>

            <a-form-item label="每日可领取上限" name="dayLimitNum" extra="不输入默认不限制">
              <a-input-number style="width: 200px;" :min="0" :precision="0" v-model:value="couponCodeModelRef.dayLimitNum" placeholder="请输入每日可领取上限"></a-input-number>
            </a-form-item>

            <a-form-item label="是否启用" name="isDisabled">
              <a-switch v-model:checked="couponCodeModelRef.isDisabled" checked-children="启用" un-checked-children="禁用" />
            </a-form-item>

            <a-form-item v-if="couponCodeModelRef.type === 2" label="图片" extra="建议图片比例：624 : 728" :rules="[{ required: true, message: '必填项不允许为空' }]">
              <a-upload
                  v-model:file-list="fileList"
                  list-type="picture-card"
                  action="/admin/ajaxUpload.do"
                  @change="handleChange"
              >
                <div v-if="fileList.length < 1">
                  <Icon icon="PlusOutlined"></Icon>
                  <div style="margin-top: 8px">Upload</div>
                </div>
              </a-upload>
            </a-form-item>
          </a-form>
        </a-spin>
      </a-modal>

<!--      <a-modal v-model:visible="showCouponCodeInfo" title="券编码和券码" width="500px">-->
<!--        <a-spin :spinning="loading">-->
<!--          <a-form scrollToFirstError :model="couponCodeInfoModelRef" name="addForm" :labelCol="{span: 6, xxl: 5}" :wrapperCol="{span: 16, xxl: 15 }">-->

<!--            <a-form-item label="券编码" name="code">-->
<!--              {{ couponCodeInfoModelRef.code }} <a-button style="margin-left: 10px;" @click="copy">复制券编码</a-button>-->
<!--            </a-form-item>-->

<!--            <a-form-item label="券二维码" name="codeUrl">-->
<!--              <a-image style="width: 120px;" :src="couponCodeInfoModelRef.codeUrl"></a-image>-->
<!--              <div>-->
<!--                <a-button style="margin-top: 10px;" @click="upLoad">下载券二维码</a-button>-->
<!--              </div>-->
<!--            </a-form-item>-->

<!--          </a-form>-->
<!--        </a-spin>-->
<!--        <template #footer>-->
<!--          <a-button type="primary" @click="showCouponCodeInfo = false;">关闭</a-button>-->
<!--        </template>-->
<!--      </a-modal>-->
    </a-spin>
    <a-spin  v-if="showModal" :spinning="loading">
      <temp :id="id" :isEdit="isEdit" @back="onBack"></temp>
    </a-spin>
  </div>
</template>

<script>
import {
  Icon
} from '@/components/icon/icon.js';
import temp from './temp.vue';
import couponList from './couponList'
import { getCinemaList } from '@/service/modules/cinema.js';
import {
  getAppCouponArchiveCode,
  getCouponList,
  updateCoupon
} from "@/service/modules/coupon";
export default {
  components: {
    Icon,
    temp,
    couponList
  },
  data() {
    return {
      id: 0,
      isEdit: false,
      loading: false,
      showAll: false,
      showCouponCode: false,
      // couponCodeInfoModelRef: {
      //
      // },
      couponCodeModelRef: {},
      formState: {},
      fileList: [],
      searchData: [],
      organizationList: [],
      cinemaAllList: [],
      visible: false,
      list: [{
        key: 1,
        title: '券标题',
        type: '代金券',
        use: '演出',
        limitUse: '￥100',
        deduction: '￥10',
        status: '启用',
        validity: '30天'
      }],
      columns: [{
        title: '标题',
        dataIndex: 'title',
      }, {
        title: '类型',
        key: 'type',
      }, {
        title: '券用途',
        key: 'useLimit',
      },{
        title: '满额可使用（元）',
        key: 'limitPrice',
      }, {
        title: '抵扣金额（元）',
        dataIndex: 'deductionPrice',
      }, {
        title: '补差金额（元）',
        dataIndex: 'diffPrice',
      }, {
        title: '状态',
        key: 'isDisabled',
      }, {
        title: '有效期类型',
        key: 'validPeriodType',
      }, {
        title: '有效期',
        key: 'period',
      }, {
        title: '操作',
        key: 'action',
        fixed: 'right',
        width: 160
      }],
      pagination: {
        showSizeChanger: true,
        showQuickJumper: true,
        size: "middle",
        pageSizeOptions: ['10','20', '50', '100', '500'],
        showTotal: (total) => {
          return "共 " + total + " 条";
        },
        total: 0,
        current: 1,
        pageSize: 10,
        onChange: (page) => {
          this.pagination.current = page;
          this.getData();
        },
        onShowSizeChange: (page, pageSize) => {
          this.pagination.current = 1;
          this.pagination.pageSize = pageSize;
          this.getData();
        }
      },
      showModal: false,
      // showCouponCodeInfo: false,
    }
  },
  created() {
    this.getData();
  },
  methods: {
    onBack(isRef) {
      this.showModal = false;
      if (isRef) {
        this.getData();
      }
    },
    reset() {
      this.$refs.form.resetFields();
      if (this.organizationList.length) {
        this.formState.organizationId = this.organizationList[0].id;
        this.getAllCinemaList(this.formState.organizationId);
      }
      this.list = [];
      this.pagination.total = 0;
      this.onSearch();
    },
    onSearch() {
      this.pagination.current = 1;
      this.searchData = JSON.parse(JSON.stringify(this.formState));
      this.getData();
    },
    async getAllCinemaList(organizationId) {
      this.cinemaAllList = [];
      this.formState.cinemaId = undefined;
      let ret = await getCinemaList({
        page: 1,
        pageSize: 999999,
        organizationId: organizationId ? organizationId : undefined
      })
      if (ret.code === 200) {
        this.cinemaAllList = ret.data.list;
      }
    },
    onAdd() {
		this.id = 0;
		this.isEdit = false;
      this.showModal = true;
    },
    onEdit(item) {
      this.showModal = true;
      this.isEdit = true;
      this.id = item.id;
    },
    onDisabled(item) {
      this.$confirm({
        title: '提示',
        content: '确定' + (item.isDisabled ? '启用' : '禁用') + '该兑换券吗？',
        onOk: async () => {
          this.loading = true;
          try {
            let ret = await updateCoupon({
              id: item.id,
              isDisabled: item.isDisabled ? 0 : 1
            })
            this.loading = false;
            if (ret.code === 200) {
              this.$message.success((item.isDisabled ? '启用' : '禁用') + '成功');
              this.getData();
            }
          } catch(e) {
            console.log(e)
            this.loading = false;
          }
        }
      })
    },
    async getData() {
      if(this.formState.periodDate && this.formState.periodDate.length === 2) {
        this.searchData.periodStartDate = this.moment(this.formState.periodDate[0].startOf('day')).unix();
        this.searchData.periodEndDate = this.moment(this.formState.periodDate[1].endOf('day')).unix();
      }
      delete this.searchData.periodDate;

      this.loading = true;
      try {
        let ret = await getCouponList({
          page: this.pagination.current,
          pageSize: this.pagination.pageSize,
          ...this.searchData
        })
        this.loading = false;
        if(ret.code === 200) {
          this.list = ret.data.list;
          this.pagination.total = ret.data.totalCount;
        }
      } catch(e) {
        this.loading = false;
      }
    },
    async onCouponCodeOk() {
      this.$refs.couponCodeForm.validateFields().then(async () => {
        this.loading = true;
        let postData = JSON.parse(JSON.stringify(this.couponCodeModelRef));
        const imgList = [];
        this.fileList.forEach(item=>{
          imgList.push(item.url);
        });
        postData.imageUrl = imgList.join(',');
        postData.couponId = this.id;
        // postData.num = parseInt(postData.num);
        if (postData.date.length) {
          postData.startDate = this.moment(postData.date[0]).startOf('days').unix();
          postData.endDate = this.moment(postData.date[1]).endOf('days').unix();
          // startDate, couponCodeModelRef.endDate
          // if(postData.startDate) {
          //   postData.startDate = this.moment(postData.startDate).endOf('days').unix();
          // }
          // if(postData.endDate) {
          //   postData.endDate = this.moment(postData.endDate).endOf('days').unix();
          // }
        }
        delete postData.date;
        // if(postData.startDate) {
        //   postData.startDate = this.moment(postData.startDate).endOf('days').unix();
        // }
        // if(postData.endDate) {
        //   postData.endDate = this.moment(postData.endDate).endOf('days').unix();
        // }
        try {
          let ret = await getAppCouponArchiveCode(postData);
          this.loading = false;
          if(ret.code === 200) {
            this.$message.success('操作成功');
            this.showCouponCode = false;
            this.couponCodeModelRef = {};
            this.couponCodeInfoModelRef = ret.data;
            this.showCouponCodeInfo = true;
          }
        } catch(e) {
          this.loading = false;
        }
      })
    },
    onCouponCode(item) {
      this.couponCodeModelRef = {};
      this.couponCodeModelRef.dayType = 1;
      this.couponCodeModelRef.type = 1;
      this.fileList = [];
      this.id = item.id;
      this.showCouponCode = true;
    },
    // copy() {
    //   const text = document.createElement("textarea");
    //   text.value = this.couponCodeInfoModelRef.code;
    //   document.body.appendChild(text);
    //   text.select();
    //   if (document.execCommand("copy")) {
    //     document.execCommand("copy");
    //   }
    //   document.body.removeChild(text);
    //   this.$message.success('复制成功');
    // },
    // async upLoad() {
    //   const imgUrl = this.couponCodeInfoModelRef.codeUrl;
    //   const response = await fetch(imgUrl);
    //   const blob = await response.blob();
    //   // 创建下载链接
    //   const url = window.URL.createObjectURL(blob);
    //   const link = document.createElement('a');
    //   link.href = url;
    //   link.download = 'image.png';
    //   document.body.appendChild(link);
    //   link.click();
    //   document.body.removeChild(link);
    //   // 释放 Blob URL
    //   window.URL.revokeObjectURL(url);
    //   this.$message.success('下载成功');
    // },
    handleChange(info) {
      if (info.file.status === 'done') {
        this.fileList = info.fileList;
        const $fileList = [...this.fileList];
        this.fileList = $fileList.map(file => {
          if (file.response) {
            file.url = file.response.data.imgUrl;
          }
          return file;
        });
      }
    },
  }
}
</script>

<style scoped>
.ui-form__item {
  margin-right: 30px;
}
</style>
